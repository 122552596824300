<template>
  <el-dialog title="修改密码" v-model="dialogVisible" @close="resetForm" center :width="dialogWidth" :show-close="showClose" :close-on-click-modal="closeOnClick" :close-on-press-escape="closeOnClick"	>
    <template #title>
      <div class="dialog-title">
        <span style="font-size: 20px">修改密码</span>
        <p class="tip-text">为了你的账号安全，请修改密码</p>
      </div>
    </template>
    <el-form ref="passwordForm" :model="passwordForm" :rules="passwordRules">
      <el-form-item prop="oldPassword">
        <el-input type="password" v-model="passwordForm.oldPassword" placeholder="请输入原密码" class="center-input"></el-input>
      </el-form-item>
      <el-form-item prop="newPassword">
        <el-input type="password" v-model="passwordForm.newPassword" placeholder="请输入新密码" class="center-input"></el-input>
      </el-form-item>
      <el-form-item prop="confirmPassword">
        <el-input type="password" v-model="passwordForm.confirmPassword" placeholder="请确认密码" class="center-input"></el-input>
      </el-form-item>
    </el-form>
      <template  v-slot:footer>
    <div class="dialog-footer">
      <el-button type="primary" @click="submitForm" style="width: 100%;">确认</el-button>
    </div>
      </template>
  </el-dialog>
</template>

<script>
export default {
  name: "changePassword",
  data() {
    return{
      //修改密码框
      dialogWidth: '300px',
      dialogVisible: false,
      dialogVisible2: false,
      showClose:false,
      closeOnClick: false,
      passwordForm: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      passwordRules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          // { validator: this.passWordTrue, trigger: 'blur',required: true }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能少于6位', trigger: 'blur' },
          { validator: this.validateNewPassword, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { validator: this.validateConfirmPassword, trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.showNeedChangePasswordDialog();
  },
  methods:{
    validateNewPassword(rule, value, callback) {
      const confirmPassword = this.passwordForm.confirmPassword;
      if (confirmPassword && value !== confirmPassword) {
        this.$refs.passwordForm.validateField('confirmPassword');
      }
      callback();
    },
    showNeedChangePasswordDialog() {
      if(localStorage.getItem('NeedMofidfyPsw') === 'true'){
        this.dialogVisible = true;
        // setTimeout(() => {
        //   this.$message({
        //     duration: 0,
        //     showClose: true,
        //     type: 'warning',
        //     message: '为了你的账号安全，请修改密码',
        //   })
        // }, 300);
      }
    },
    submitForm() {
      this.$refs.passwordForm.validate(valid => {
        if (valid) {
          var pass = {
            OldPsw: this.passwordForm.oldPassword,
            NewPsw: this.passwordForm.newPassword
          }
          // 执行密码修改操作
          this.$axios({
            url: this.localpath + 'managers/updatePsw',
            method: 'post',
            //发送格式为json
            data: JSON.stringify(pass),
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            // ElMessage.closeAll()
            if (res.data.ok) {
              localStorage.setItem('NeedMofidfyPsw','false')
              // localStorage.setItem('passWord',pass.NewPsw)
              this.dialogVisible = false
              this.$message({
                type: 'success',
                message: '修改密码成功!',
                duration: 2000
              })
            }else{
              //错误原因
              // this.$message({
              //   showClose: true,
              //   message: res.data.msg,
              //   type: 'error'
              // })
              const tipTextElement = document.getElementsByClassName('tip-text')[0];
              tipTextElement.innerText = res.data.msg
            }
          })
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.passwordForm.resetFields();
    },
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.passwordForm.newPassword) {
        callback(new Error('两次输入的密码不一致'));
      } else {
        callback();
      }
    },
  }
}
</script>

<style scoped>
.center-input {
  text-align: center;
}

/deep/ .el-dialog--center {
  text-align: center;
  margin-top: 25vh !important;
}
.dialog-title {
  text-align: center;
}

.dialog-title h3 {
  margin: 0;
}

.dialog-title .tip-text {
  color: red;
  font-size: 12px;
}

</style>
