<template>
  <div class="container">
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card shadow="hover" class="mgb20" style="height: 195px">
          <div class="user-info">
            <img :src="image" class="user-avator" alt />
            <div class="user-info-cont">
              <div class="user-info-name">{{ name }}</div>
            </div>
          </div>
        </el-card>
        <el-card shadow="hover" style="height: 150px">
          <template #header>
            <div class="clearfix">
              <span>借阅数据统计：</span>
            </div>
          </template>
          <p>今日借阅数量:{{ todayborrow }}</p>
          <br />
          <p>今日归还数量:{{ todayreturn }}</p>
          <br />
          <p>近30天借阅数量:{{ monthborrow }}</p>
          <br />
          <p>近30天归还数量:{{ monthreturn }}</p>
        </el-card>
      </el-col>
      <el-col :span="16">
        <el-row :gutter="10" class="mgb20">
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-1">
                <i class="el-icon-notebook-1 grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ totalbookscount }}</div>
                  <div>馆藏总量</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-2">
                <i class="el-icon-view grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ totalborrow }}</div>
                  <div>累计借阅</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-3">
                <i class="el-icon-finished grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ totalreturn }}</div>
                  <div>累计归还</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-card shadow="hover">
          <schart
            ref="line"
            class="schart"
            canvasId="trends"
            :options="options"
            v-loading="trendload"
          ></schart>
        </el-card>
      </el-col>
    </el-row>

    <!-- <el-row :gutter="10">
      <el-col :span="12">
        <el-card shadow="hover">
          <schart
            ref="bar"
            class="schart"
            canvasId="class"
            :options="options1"
          ></schart>
        </el-card>
      </el-col> -->
    <el-row :gutter="10">
      <el-col :span="12">
        <el-card shadow="hover">
          <div id="rankClass" style="width: 100%; height: 300px"></div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="hover">
          <schart
            ref="bar"
            class="schart"
            canvasId="student"
            :options="options2"
          ></schart>
        </el-card>
      </el-col>
    </el-row>
    <change-password></change-password>
<!--    <el-dialog title="修改密码" v-model="dialogVisible2" center :width="dialogWidth" :show-close="showClose" :close-on-click-modal="closeOnClick" :close-on-press-escape="closeOnClick"	>-->
<!--      <div style="margin-bottom: 30px;text-align: center">-->
<!--        超过六个月未修改密码，请修改密码-->
<!--      </div>-->
<!--      <div slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" @click="changePass" style="width: 100%;">确认</el-button>-->
<!--      </div>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import Schart from "vue-schart";
import * as echarts from "echarts";
import changePassword from "@/components/changePassword";
export default {
  name: "dashboard",
  data() {
    return {
      image: 1,
      todayborrow: 0,
      todayreturn: 0,
      monthborrow: 0,
      monthreturn: 0,
      totalbookscount: 0,
      totalborrow: 0,
      totalreturn: 0,
      trendload: true,
      name: localStorage.getItem("username"),
      // options1: {
      //   type: "bar",
      //   title: {
      //     text: "小组积分排名",
      //   },
      //   xRorate: 0,
      //   labels: ["没有小组有积分"],
      //   datasets: [
      //     {
      //       label: "小组积分",
      //       data: [0],
      //       fillColor: "rgb(234,114,148)",
      //     },
      //   ],
      // },
      options1: {
            color: ["rgb(232,149,119)"],
            title: {
              text: "小组积分排行",
              left: "center",
            },
            legend: {
              data: ["小组积分"],
              left: "center",
              top: "10%",
            },
            grid: {
              top: "25%",
            },
            xAxis: {
              type: "category",
              data:["暂无小组积分"]
            },
            yAxis: {
              type: "value",
              min: 0,
              max: 10,
            },
            series: [
              {
                name: "小组积分",
                data: [],
                type: "bar",
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: "top", //在上方显示
                      textStyle: {
                        //数值样式
                        color: "rgb(232,149,119)",
                        fontSize: 12,
                      },
                    },
                  },
                },
              },
            ],
        },
      options: {
        type: "line",
        title: {
          text: "借还趋势",
        },
        labels: ["没人借还"],
        datasets: [
          {
            label: "借阅",
            data: [0],
            fillColor: "rgb(88,189,157)",
          },
          {
            label: "归还",
            data: [0],
            fillColor: "rgb(107,160,236)",
          },
        ],
      },
      options2: {
        type: "bar",
        title: {
          text: "个人积分排名",
        },
        xRorate: 0,
        labels: ["没有人有积分"],
        datasets: [
          {
            label: "个人积分",
            data: [0],
            fillColor: "rgb(232,149,119)",
          },
        ],
      },


    };
  },
  components: {
    Schart,changePassword
  },
  mounted() {
    //解决echarts图表第一次加载显示空白问题 暂时没找到好方案 ?
    // if (location.href.indexOf("#reloaded") == -1) {
    //   location.href = location.href + "#reloaded";
    //   location.reload();
    // }
    var rankCharst = echarts.init(document.getElementById("rankClass"));
    rankCharst.setOption(this.options1);
  },
  watch: {
    options1: {
      handler(newVal, oldVal) {
        var rankCharst = echarts.init(document.getElementById("rankClass"));
        rankCharst.setOption(newVal);
        console.log(oldVal)
      },
      deep: true, //对象内部属性的监听，关键。
    },
  },
  computed: {},
  created() {
    this.img();
    this.monthborrowed();
    this.monthreturned();
    this.todayborrowed();
    this.todayreturned();
    this.totalbooks();
    this.totalborrowed();
    this.totalreturned();
    this.classranking();
    this.studentranking();
    this.dotrend();

    //获取首页跳转路径
    var path = localStorage.getItem("active");
    if (path) {
      this.$router.push(path);
      localStorage.removeItem("active");
    }
  },
  methods: {
    //获取封面
    img() {
      // this.$axios({
      //   url: this.localpath + 'organizations/images',
      //   method: 'get',
      //   //发送格式为json
      //   headers:
      //       {
      //         'Content-Type': 'application/json',
      //         'dataType': 'json',
      //         'Authorization': localStorage.getItem("token")
      //       }
      // }).then((res) => {
      //   this.image = res.data.msg[0]
      this.image = localStorage.getItem("schoolimg");
      // })
    },
    //近30天借阅
    monthborrowed() {
      this.$axios({
        url: this.localpath + "organizations/30daysborrowedcount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.monthborrow = res.data.msg;
      });
    },
    //近30天归还
    monthreturned() {
      this.$axios({
        url: this.localpath + "organizations/30daysreturncount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.monthreturn = res.data.msg;
      });
    },
    //今日借阅
    todayborrowed() {
      this.$axios({
        url: this.localpath + "organizations/todayborrowedcount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.todayborrow = res.data.msg;
      });
    },
    //今日归还
    todayreturned() {
      this.$axios({
        url: this.localpath + "organizations/todayreturncount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.todayreturn = res.data.msg;
      });
    },
    //馆藏总量
    totalbooks() {
      this.$axios({
        url: this.localpath + "organizations/totalbookscount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.totalbookscount = res.data.msg;
      });
    },
    //借阅总量
    totalborrowed() {
      this.$axios({
        url: this.localpath + "organizations/totalborrowedcount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.totalborrow = res.data.msg;
      });
    },
    //归还总量
    totalreturned() {
      this.$axios({
        url: this.localpath + "organizations/totalreturncount",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        this.totalreturn = res.data.msg;
      });
    },
    //小组排名
    classranking() {
      this.$axios({
        url: this.localpath + "organizations/topgroups",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        // eslint-disable-next-line no-unused-vars
        var a = 0;
        if (res.data.msg.length > 6) {
          a = 6;
        } else {
          a = res.data.msg.length;
        }
        var classrankingdata = [];
        var classrankingname = [];
        for (var i = 0; i < a; i++) {
          classrankingdata[i] = res.data.msg[i].IntIntegral;
          // classrankingname[i]=res.data.msg[i].StrDepartmentName+"届"+res.data.msg[i].StrName
          //解决x轴文字过长的问题
          if (a > 4) {
            classrankingname[i] =
              (res.data.msg[i].StrDepartmentName.length > 5
                ? this.tnString(res.data.msg[i].StrDepartmentName)
                : res.data.msg[i].StrDepartmentName) +
              "-" +
              (res.data.msg[i].StrName.length > 5
                ? this.tnString(res.data.msg[i].StrName)
                : res.data.msg[i].StrName);
          } else {
            classrankingname[i] =
              res.data.msg[i].StrDepartmentName + "-" + res.data.msg[i].StrName;
          }
        }
        this.options1.xAxis.data = classrankingname.length==0?['暂无小组积分']:classrankingname
        this.options1.yAxis.max = classrankingdata.length==0?10:null
        this.options1.series[0].data = classrankingdata
      // this.options1.labels = classrankingname;
      // this.options1.datasets[0].data = classrankingdata;
    })
    },

    //字符串换行
    tnString(str) {
      var newStr = "";
      for (var i = 0; i < str.length; i++) {
        if (i != 0 && i % 5 == 0) {
          newStr += "\n";
        }
        newStr += str[i];
      }
      return newStr;
    },
    //个人排名
    studentranking() {
      this.$axios({
        url: this.localpath + "organizations/topreaders",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        var b = 0;
        if (res.data.msg.length > 6) {
          b = 6;
        } else {
          b = res.data.msg.length;
        }
        var studentrankingdata = [];
        var studentrankingname = [];
        for (var i = 0; i < b; i++) {
          studentrankingdata[i] = res.data.msg[i].IntIntegral;
          studentrankingname[i] = res.data.msg[i].StrName;
        }
        this.options2.labels = studentrankingname;
        this.options2.datasets[0].data = studentrankingdata;
      });
    },
    //借还趋势
    trend() {
      //归还
      this.$axios({
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",

          Authorization: localStorage.getItem("token"),
        },
        url: this.localpath + "organizations/returntrends",
        method: "get",
      }).then((res) => {
        var returns = [];
        for (var i = 0; i < 7; i++) {
          returns[6 - i] = res.data.msg[i].number;
        }
        this.options.datasets[1].data = returns;
      });
      //借阅
      this.$axios({
        url: this.localpath + "organizations/borrowtrends",
        method: "get",
        //发送格式为json
        headers: {
          "Content-Type": "application/json",
          dataType: "json",
          Authorization: localStorage.getItem("token"),
        },
      }).then((res) => {
        var days = [];
        var borrows = [];
        for (var i = 0; i < 7; i++) {
          days[6 - i] = res.data.msg[i].id;
          borrows[6 - i] = res.data.msg[i].number;
        }
        this.options.labels = days;
        this.options.datasets[0].data = borrows;
      });
    },
    dotrend() {
      this.trend();
      this.trendload = false;
    },
  },
};
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
  background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 14px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 30px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}
</style>
